import cacheApi from '@/core/cacheApi'
import type { ApiErrorResponse, ApiResponse } from '@/types/apiResponse'
import { useLanguage } from '@/composables/language'
import { handleError } from '@/helpers/handleError'

const { mainLang } = useLanguage()
export async function getAllCategoriesFn(): Promise<ApiResponse> {
  try {
    const allCategories: any[] = []
    const { data, headers } = await cacheApi.get(`/wp-json/wc/v3/products/categories`, {
      params: {
        page: 1,
        per_page: 100,
        lang: mainLang,
        menu_order: 'asc'
      }
    })
    const totalPages = parseInt(headers['x-wp-totalpages'])
    allCategories.push(...data)
    for (let page = 2; page <= totalPages; page++) {
      const { data } = await cacheApi.get(`/wp-json/wc/v3/products/categories`, {
        params: {
          page,
          per_page: 100,
          lang: mainLang
        }
      })
      allCategories.push(...data)
    }

    return {
      success: true,
      data: {
        allCategories
      }
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
