import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { getCategories } from '@/api/categories/getCategories'
import { getAllCategoriesFn } from '@/api/categories/getAllCategories'
import { deleteCategory } from '@/api/categories/deleteCategory'
import { createCategory, type CategoryCreate } from '@/api/categories/createCateogry'
import type { Category } from '@/types/category'
import type { QueryParameters } from '@/types'

interface CategoryStore {
  categories: Category[]
  allCategories: any
  categoryNameList: any
  totalItems: Ref<number>
  loading: boolean
}

export const useCategoriesStore = defineStore('categories', {
  state: (): CategoryStore => ({
    categories: [],
    allCategories: [],
    categoryNameList: [],
    totalItems: ref(0),
    loading: false
  }),

  actions: {
    async getCategories(options: QueryParameters) {
      this.loading = true
      const { data, success } = await getCategories(options)
      if (success) {
        this.categories = data.categories
        this.totalItems = data.total_items
        this.loading = false
      }
    },
    async getAllCategories() {
      if (this.allCategories.length > 0) return
      this.loading = true
      const { data, success } = await getAllCategoriesFn()
      if (success) {
        this.allCategories = data.allCategories
        this.categories = data.allCategories
        this.totalItems = data.allCategories.length
        this.loading = false
      }
    },
    async addCategory(category: Category) {
      this.categories.unshift(category)
      this.incrementTotalItems()
    },
    async deleteCategory(id: number) {
      const { success, error } = await deleteCategory(id)
      if (success) {
        this.categories = this.categories.filter((category: Category) => category.id !== id)
        this.decrementTotalItems()
        return {
          success: true,
          error
        }
      }
      return {
        success: false,
        error
      }
    },

    incrementTotalItems() {
      this.totalItems++
    },

    decrementTotalItems() {
      this.totalItems--
    },
    async createNewCategory(categoryData: CategoryCreate) {
      const { data: mainLang_category, success } = await createCategory(categoryData)
      if (!success) return
      this.allCategories.unshift(mainLang_category)
    }
  }
})
