import { toast, type Id } from 'vue3-toastify'
export const toastDone = (toastId: Id, toastMessage: string) => {
  toast.update(toastId, {
    render: toastMessage,
    autoClose: true,
    closeOnClick: true,
    closeButton: true,
    type: 'success',
    isLoading: false
  })
  toast.done(toastId)
}
