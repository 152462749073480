import { useCategoriesStore } from '@/stores/categories'
import type { Category } from '@/types/category'
import type { Option } from '@/types/option'

export function getNames(items: Category[]): string[]
export function getNames(items: Option[]): string[]
export function getNames(items: (Category | Option)[]) {
  return items.map((item) => item.name)
}

export function formatCategoryName(category: string) {
  const formattedName = category.split('&')
  return formattedName[0]
}

export function mapCategoriesNameToId(names: string[]) {
  const store = useCategoriesStore()

  const categoriesFoundInStore: Omit<Category, 'name' | 'translation_strings'>[] = []
  names.forEach((name) => {
    const categoryFound = store.categories.find((category) => category.name === name)
    if (categoryFound) {
      categoriesFoundInStore.push({ id: categoryFound.id })
    }
  })

  return categoriesFoundInStore
}

export function formatDescription(desc: string) {
  const descFormat = desc
  // const patterns = ['<p>', '</p>']

  // patterns.forEach((pattern) => (descFormat = descFormat.replace(pattern, '')))

  // descFormat = descFormat.replace(/\n/g, ' ')

  return descFormat
}

export function mapVisibility(status: boolean | string) {
  if (typeof status === 'boolean') {
    return status ? 'visible' : 'hidden'
  }

  if (typeof status === 'string') {
    return status === 'visible' ? true : false
  }
}

export function formatImage(image: ApiImage) {
  return {
    id: image.id,
    src: image.source_url
  }
}

export function mapOrderStatus(status: string) {
  switch (status) {
    case 'pending':
      return 'معلق'
    case 'processing':
      return 'قيد المعالجة'
    case 'on-hold':
      return 'في الانتظار'
    case 'completed':
      return 'مكتمل'
    case 'cancelled':
      return 'ملغي'
    case 'refunded':
      return 'المستردة'
    case 'failed':
      return 'فشل'
    case 'trash':
      return 'مهملات'
    case 'preparing':
      return 'جاري التحضير'
    case 'readytoship':
      return 'جاهزة للشحن'
    default:
      return ''
  }
}

export function formatDate(date: any) {
  const tmp = new Date(date)
  const f = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'medium',
    timeStyle: 'short'
  })
  return f.format(tmp)
}

export type ApiImage = {
  id: number
  source_url: string
}

export type Image = {
  id: number
  src: string
}
