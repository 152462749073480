import api from '@/core/api'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import { handleError } from '@/helpers/handleError'
import { toast } from 'vue3-toastify'
import type { Image } from '@/types/image'
import { toastDone } from '../util/toastHelper'

type ProductInfoUpdate = {
  name: string
  regular_price?: number
  sale_price?: number
  description: string
  short_description?: string
  secondLangDescription: string
  type?: string
  categories: {
    id: number
  }[]
  tags: {
    id: number
  }[]
  visibility: string
  catalog_visibility?: string
  featured: boolean
}

type ProductAttributeUpdate = {
  id: number
  name: string
  type?: string
  regular_price?: number
  sale_price?: number
  short_description?: string
  position: number
  status?: string
  description: string
  visible: boolean
  variation: boolean
  options: string[]
}
type UpdateData =
  | ProductInfoUpdate
  | { attributes: ProductAttributeUpdate[] }
  | { images: Image[] }
  | any

export async function updateProduct(
  updateData: Partial<UpdateData>,
  id: string | number
): Promise<ApiResponse> {
  try {
    const toastId = toast.loading('جاري تعديل المنتج')
    updateData.short_description = updateData.description
    const { data } = await api.put(`/wp-json/wc/v3/products/${id}`, updateData)
    toastDone(toastId, 'تم تعديل المنتج بنجاح')
    return {
      success: true,
      data
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
