export function useLanguage() {
  // Getting main and second languages from environment variables
  const mainLang: string = import.meta.env.VITE_MAIN_LANG
  const secondLang: string = import.meta.env.VITE_SECOND_LANG

  // Define title prefixes based on the main and second languages
  const mainInputTitlePrefix = getInputTitlePrefix(mainLang)
  const secondInputTitlePrefix = getInputTitlePrefix(secondLang)

  // Boolean for checking if two languages are enabled
  const isTreesalTwoLang = import.meta.env.VITE_TREESAL_TWO_LANG === 'true'

  // Helper function to handle multiple languages
  function getInputTitlePrefix(lang: string): string {
    switch (lang) {
      case 'ar':
        return 'بالعربية'
      case 'en_US':
        return 'بالإنجليزية'
      case 'fr':
        return 'بالفرنسية'
      case 'he_IL':
        return 'بالعبرية'
      default:
        return `باللغة ${lang}` // Default case for other languages
    }
  }

  return {
    mainLang,
    secondLang,
    mainInputTitlePrefix,
    secondInputTitlePrefix,
    isTreesalTwoLang
  }
}
