import cacheApi from '@/core/cacheApi'
import type { QueryParameters } from '@/types'
import { toast } from 'vue3-toastify'
import { handleError } from '@/helpers/handleError'
import type { ApiErrorResponse, ApiResponse } from '@/types/apiResponse'

export interface OrderQueryParameters extends QueryParameters {
  customer?: number
}

export async function getAllOrders({
  page,
  itemsPerPage,
  sortBy,
  after,
  before,
  status: orderStatus,
  customer
}: OrderQueryParameters): Promise<ApiResponse> {
  try {
    const { data, status, headers } = await cacheApi.get(`/wp-json/wc/v3/orders`, {
      params: {
        page: page || 1,
        per_page: itemsPerPage || 10,
        order: sortBy?.length ? sortBy[0].order : null,
        orderBy: sortBy?.length ? sortBy[0].key : null,
        after: after || null,
        before: before || null,
        status: orderStatus || null,
        customer: customer ?? null
      }
    })

    return {
      success: true,
      data: {
        orders: data,
        total_items: parseInt(headers['x-wp-total'])
      }
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
