import cacheApi from '@/core/cacheApi'
import { handleError } from '@/helpers/handleError'

import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'

export async function getProductById(id: number | string): Promise<ApiResponse> {
  try {
    const { data, status } = await cacheApi.get(`/wp-json/wc/v3/products/${id}`)

    if (status !== 200) {
      return {
        success: false
      }
    }
    return {
      success: true,
      data
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
