import { toast } from 'vue3-toastify'
import api from '@/core/api'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import type { Lang } from '@/types'
import { handleError } from '@/helpers/handleError'
import { toastDone } from '../util/toastHelper'

export interface CategoryCreate {
  name: string
  secondLangName?: string
  slug?: string
  parent?: number
  lang: Lang
  translation_of?: number
  translation_strings?: {
    [key: string]: string
  }
}

export async function createCategory(category: CategoryCreate): Promise<ApiResponse> {
  try {
    const toastId = toast.loading('جاري  انشاء التصنيف')
    const { data, status } = await api.post(`/wp-json/wc/v3/products/categories`, category)
    if (status !== 201) {
      return {
        success: false
      }
    }
    toastDone(toastId, 'تم انشاء التصنيف بنجاح')
    return {
      success: true,
      data
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
