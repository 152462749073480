// Vuetify
import '@/styles/style.scss'
import '@/styles/settinges.scss'
import { createVuetify } from 'vuetify'
import { VTimePicker } from 'vuetify/labs/VTimePicker'

import '@mdi/font/css/materialdesignicons.css'
import theme from './theme'

import { ar } from 'vuetify/locale'

const vuetify = createVuetify({
  components: { VTimePicker },
  theme,
  locale: {
    locale: 'ar',
    fallback: 'ar',
    messages: { ar },
    rtl: { ar: true }
  }
})

export default vuetify
