import api from '@/core/api'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import { handleError } from '@/helpers/handleError'
import { toast } from 'vue3-toastify'
import { toastDone } from '../util/toastHelper'

export type Payload = {
  [key: string]: number | string | Payload | Payload[]
}

export async function updateOrder(id: number | string, payload: Payload): Promise<ApiResponse> {
  try {
    const toastId = toast.loading('جاري تعديل الطلب')
    const { data, status } = await api.put(`/wp-json/wc/v3/orders/${id}`, payload)
    
    toastDone(toastId, 'تم تعديل الطلب بنجاح')
    return {
      success: true,
      data
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
