import Pusher from 'pusher-js'

const pusher_key = import.meta.env.VITE_PUSHER_KEY
const pusher = pusher_key
  ? new Pusher(pusher_key, {
      forceTLS: true,
      cluster: 'eu'
    })
  : null

export default pusher
