/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import pinia from '@/stores'
import router from '@/router'
import vuetify from './vuetify/vuetify'
import ability from '@/plugins/casl/ability'
import { abilitiesPlugin } from '@casl/vue'
import Vue3Toasity, { type ToastContainerOptions, toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
// Types
import type { App } from 'vue'

export function registerPlugins(app: App) {
  app.use(router).use(pinia).use(vuetify)
  app
    .use(abilitiesPlugin, ability, {
      useGlobalProperties: true
    })
    .use(Vue3Toasity, {
      autoClose: 3000,
      rtl: true,
      closeButton: true,
      position: toast.POSITION.TOP_CENTER,
      bodyClassName: 'toastText'
    } as ToastContainerOptions)
}
