<script setup lang="ts">
import { RouterView } from 'vue-router'
import { useOrdersStore } from './stores/orders'
import { onMounted } from 'vue'
import pusher from './helpers/pusher'
import { toast } from 'vue3-toastify'
import { useRoute } from 'vue-router'

const route = useRoute()
onMounted(() => {
  const ordersStore = useOrdersStore()
  if (pusher != null) {
    const channel = pusher.subscribe('vue-orders')
    channel.bind('new-order', (data: any) => {
      if (ordersStore.newOrders.some((order) => order.id == data.id)) return
      toast.success(`تم اضافة طلب جديد `)
      console.log(data)
      ordersStore.newOrders.unshift(data)
      if (route.path == '/orders/new-orders') return
      ordersStore.showBadge = true
    })
  }
})
</script>
<template>
  <RouterView />
</template>
