import cacheApi from '@/core/cacheApi'
import { handleError } from '@/helpers/handleError'
import { formatProduct } from '@/helpers/formatProduct'
import type { ApiErrorResponse, ApiResponse } from '@/types/apiResponse'
import type { QueryParameters } from '@/types'
import { useLanguage } from '@/composables/language'
const { mainLang } = useLanguage()
export async function getAllProducts({
  page,
  itemsPerPage,
  search,
  category,
  tag,
  stock_status,
  order,
  status,
  sku,
  orderBy
}: QueryParameters): Promise<ApiResponse> {
  try {
    const { data, headers } = await cacheApi.get(`/wp-json/wc/v3/products`, {
      params: {
        page: page || 1,
        per_page: itemsPerPage || 10,
        search: search || null,
        orderby: orderBy || 'date',
        category: category || null,
        order: order || 'desc',
        stock_status: stock_status,
        status: status,
        sku: sku || null,
        tag: tag || null,
        lang: mainLang
      }
    })
    const finalData = data.map((product: any) => formatProduct(product))
    return {
      success: true,
      data: { products: finalData, total_items: headers['x-wp-total'] as number }
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
