import ability from '@/plugins/casl/ability'
import { id } from 'vuetify/lib/locale/index.mjs'

const navigationItems = [
  {
    id: 1,
    title: 'المنتجات',
    key: 'products',
    ability: 'Products',
    to: '/products',
    icon: { icon: 'mdi mdi-store' },
    subItems: []
  },
  {
    id: 2,
    title: 'التصنيفات',
    key: 'categories',
    ability: 'Categories',
    to: '/categories',
    icon: { icon: 'mdi-sitemap-outline' },
    subItems: <any>[]
  },
  {
    id: 16,
    title: 'الوسوم',
    key: 'tags',
    ability: 'Tags',
    to: '/tags',
    icon: { icon: 'mdi-tag-multiple' },
    subItems: <any>[]
  },
  {
    id: 3,
    title: 'الوحدات',
    key: 'attributes',
    ability: 'Attributes',
    to: '/attributes',
    icon: { icon: 'mdi-counter' },
    subItems: <any>[]
  },

  {
    id: 5,
    title: 'العملاء',
    key: 'customers',
    ability: 'Customers',
    to: '/customers',
    icon: { icon: 'mdi-account-group' },
    subItems: <any>[]
  },
  {
    id: 6,
    title: 'الطلبات',
    key: 'orders',
    ability: 'Orders',
    icon: { icon: 'mdi mdi-cart-variant' },
    subItems: <any>[
      {
        id: 1,
        title: 'كل الطلبات ',
        key: 'orders',
        ability: 'Orders',
        to: '/orders',
        icon: { icon: 'mdi mdi-cart-variant' }
      },
      {
        id: 2,
        title: 'تعديل سريع',
        key: 'quikOrders',
        ability: 'Orders',
        to: '/orders/quick-edit',
        icon: { icon: 'mdi mdi-pencil' }
      },
      {
        id: 2,
        title: 'الطلبات الجديدة ',
        key: 'newOrders',
        ability: 'Orders',
        to: '/orders/new-orders',
        icon: { icon: 'mdi mdi-cart-check' }
      }
    ]
  },
  {
    id: 7,
    title: 'الكوبونات',
    key: 'coupons',
    ability: 'Coupons',
    to: '/coupons',
    icon: { icon: 'mdi-ticket-percent-outline' },
    subItems: <any>[]
  },
  {
    id: 8,
    title: 'التقارير',
    key: 'reports',
    ability: 'Reports',
    to: '/reports',
    icon: { icon: 'mdi-file-chart-outline' },
    subItems: <any>[]
  },
  {
    id: 9,
    title: 'وسائل التوصيل',
    key: '',
    ability: 'Shipping',
    to: '/shipping/zones/0',
    icon: { icon: 'mdi mdi-truck' },
    subItems: <any>[]
  },
  {
    id: 10,
    title: 'المهملات',
    key: 'trah',
    ability: 'Trash',
    to: '/trash',
    icon: { icon: 'mdi mdi-delete-variant' },
    subItems: <any>[]
  },
  {
    id: 11,
    title: 'الاعدادات',
    key: 'settings',
    ability: 'Settings',
    to: '/settings',
    icon: { icon: 'mdi mdi-cog' },
    subItems: <any>[]
  },
  {
    id: 13,
    title: 'جدول المقاسات',
    key: '',
    ability: 'SizeCharts',
    to: '/size-charts',
    icon: { icon: 'mdi mdi-table' },
    subItems: <any>[]
  },
  {
    id: 12,
    title: 'التحديثات',
    key: 'updates',
    ability: 'Updates',
    to: '/release-notes',
    icon: { icon: 'mdi mdi-update' }
  },
  {
    id: 13,
    title: 'أدوات مفيدة',
    key: 'tools',
    ability: 'Tools',
    to: '/tools',
    icon: { icon: 'mdi mdi-wrench' },
    subItems: <any>[]
  },
  {
    id: 14,
    title: 'فحص تاريخ الميلاد',
    key: 'birthdays',
    ability: 'Birthdays',
    to: '/birthdays',
    icon: { icon: 'mdi mdi-cake-variant' },
    subItems: <any>[]
  },
  {
    id: 15,
    title: ' التقييمات والاسئلة',
    key: 'reviews',
    ability: 'Reviews',
    to: '/reviews',
    icon: { icon: 'mdi mdi-star' },
    subItems: <any>[]
  },
  {
    id: 15,
    title: 'العلامات التجارية',
    key: 'brands',
    ability: 'Brands',
    to: '/brands',
    icon: { icon: 'mdi mdi-label' },
    subItems: <any>[]
  }
]

export default navigationItems
