import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { getAllProducts } from '@/api/products/getAllProducts'
import { getSearchProducts } from '@/api/products/getSearchProducts'
import { deleteProduct } from '@/api/products/deleteProduct'
import type { Product } from '@/types/product'
import { batchProductsUpdate } from '@/api/products/batchProductsUpdate'
import type { QueryParameters } from '@/types'
import { useLanguage } from '@/composables/language'
import { updateProduct } from '@/api/products/updateProduct'
import { formatProduct } from '@/helpers/formatProduct'
import { getProductById } from '@/api/products/getProductById'
const { secondLang } = useLanguage()
interface ProdustsState {
  products: Ref<Product[]>
  searchProducts: Ref<any[]>
  loadedProducts: Ref<any>
  currentPage: Ref<number>
  searchLoading: boolean
  trashProducts: Ref<Product[]>
  selectedProducts: Ref<any[]>
  totalItems: Ref<number>
  totalTrashItems: Ref<number>
  trashDeletedItems: number
  loading: boolean
  duplicatesItems: Ref<Number[]>
  scrollY: number
  filters: Ref<any[]>
  deletedItems: number
}

export const useProductsStore = defineStore('products', {
  state: (): ProdustsState => ({
    products: ref([]),
    searchProducts: ref([]),
    trashProducts: ref([]),
    searchLoading: false,
    loadedProducts: ref({}),
    currentPage: ref(1),
    totalItems: ref(0),
    selectedProducts: ref([]),
    totalTrashItems: ref(0),
    loading: true,
    duplicatesItems: ref([]),
    scrollY: 0,
    deletedItems: 0,
    trashDeletedItems: 0,
    filters: ref([undefined, 'desc', 'date', 'all', undefined, undefined])
  }),

  actions: {
    async getProducts(options: QueryParameters, force: boolean = false) {
      if (force) this.loadedProducts = {}
      if (this.loadedProducts[options?.page] && !force) {
        this.products = this.loadedProducts[options?.page]
        return
      }
      this.loading = true
      const { success, data } = await getAllProducts(options)
      if (success) {
        this.loading = false
        this.totalItems = data!.total_items
        this.products = data!.products
        this.loadedProducts[options?.page] = data!.products
        this.duplicatesItems = []
      }
    },
    async getSearchProducts(query: any) {
      this.searchLoading = true
      const { success, data } = await getSearchProducts(query)
      if (success) {
        this.searchLoading = false
        this.searchProducts = data
      }
    },

    async getTrashProducts(options: QueryParameters) {
      this.loading = true
      const { success, data } = await getAllProducts(options)
      if (success) {
        this.loading = false
        this.totalTrashItems = data!.total_items
        this.trashProducts = data!.products
        this.duplicatesItems = []
      }
    },
    async getInfiniteProducts(options: QueryParameters) {
      this.loading = true
      const { success, data } = await getAllProducts(options)
      if (success) {
        this.loading = false
        if (options?.status == 'trash') {
          this.totalTrashItems = data!.total_items
          this.trashProducts.push(...data.products)
        } else {
          this.totalItems = data!.total_items
          this.products.push(...data.products)
        }
        this.duplicatesItems = []
      }
    },

    async deleteProductFromStore(id: number) {
      let index = this.products.findIndex((item) => item.id === id)
      if (index === -1) {
        const { data, success } = await getProductById(id)
        if (success) {
          this.products.push(formatProduct(data))
          index = this.products.length - 1
        }
      }
      const res = await deleteProduct(id)
      const translations = this.products[index].translations
      if (translations && translations[secondLang]) {
        await deleteProduct(translations[secondLang])
      }
      this.products.splice(index, 1)
      return {
        success: true
      }
      return res
    },

    async deleteProductFromTrashStore(id: number) {
      const index = this.trashProducts.findIndex((item) => item.id === id)
      if (index === -1) {
        return {
          success: false
        }
      }
      const res = await deleteProduct(id + '?force=true')
      const translations = this.products[index]?.translations
      if (translations && translations[secondLang]) {
        await deleteProduct(translations[secondLang] + '?force=true')
      }
      this.trashProducts.splice(index, 1)
      // this.loadedProducts = {}
      return {
        success: true
      }
      return res
    },

    async restoreTrashedProduct(payload: any, id: number) {
      const index = this.trashProducts.findIndex((item) => item.id === id)
      if (index === -1) {
        return {
          success: false
        }
      }
      const res = await updateProduct(payload, id)
      this.trashProducts.splice(index, 1)
      return {
        success: true
      }
      return res
    },

    async batchUpdate(payload: any) {
      const { success, data, error } = await batchProductsUpdate(payload)
      if (success) {
        this.loading = false
        return {
          success: true,
          data
        }
      } else
        return {
          error
        }
    }
  }
})
