import { toast } from 'vue3-toastify'
import { useRouter } from 'vue-router'

const router = useRouter()
export const handleError = (error: any) => {
  if (error?.data?.status == 403) {
    toast.error(' غير مسموح لك الوصول لهذه البيانات ')
  } else {
    if (error?.message) toast.error(error?.message)
    else toast.error('حدث خطأ في العملية')
  }
}
