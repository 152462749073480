import type { UserAbility } from '@/plugins/casl/AppAbility'

export const analyticsViewerAbilities: UserAbility[] = [
  //Reports
  {
    action: 'read',
    subject: 'Reports'
  },
  {
    action: 'create',
    subject: 'Reports'
  },

  {
    action: 'update',
    subject: 'Reports'
  },
  {
    action: 'delete',
    subject: 'Reports'
  }
]
