import type { Ref } from 'vue'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getAllOrders, type OrderQueryParameters } from '@/api/orders/getAllOrders'
import { deleteOrder } from '@/api/orders/deleteOrder'
import { updateOrders } from '@/api/orders/updateOrders'
import { updateOrder } from '@/api/orders/updateOrder'

import type { Order } from '@/types/order'
interface OrderStore {
  orders: Order[]
  newOrders: Order[]
  showBadge: boolean
  errorEditedOrders: any
  totalItems: Ref<number>
  trashOrders: Order[]
  trashTotalItems: Ref<number>
  selectedTrashOrders: number[]
  quickEditedOrders: Order[]
  loading: boolean
  page: Ref<number>
  itemsPerPage: Ref<number>
  selected: number[]
  statusFilter?: Ref<any>
  scrollY?: Ref<any>
  customerFilter?: Ref<any>
  dateFilter?: Ref<Date[]>
}

export const useOrdersStore = defineStore('orders', {
  state: (): OrderStore => ({
    orders: [],
    newOrders: [],
    showBadge: false,
    loading: false,
    page: ref(1),
    errorEditedOrders: {},
    itemsPerPage: ref(100),
    totalItems: ref(0),
    selected: [],
    trashOrders: [],
    quickEditedOrders: [],
    trashTotalItems: ref(0),
    selectedTrashOrders: [],
    statusFilter: ref([]),
    scrollY: ref(''),
    customerFilter: ref(''),
    dateFilter: ref([])
  }),
  actions: {
    async getOrders(options: OrderQueryParameters | any) {
      this.loading = true
      if (!options?.status) options.status = ['pending', 'processing', 'on-hold', 'refunded']
      const { data, success } = await getAllOrders(options)
      if (success) {
        this.orders = data.orders
        this.totalItems = data.total_items
        this.loading = false
      }
      return {
        success
      }
    },

    async getNewOrders() {
      this.loading = true
      const options: any = {}
      options['status'] = ['pending', 'processing', 'on-hold', 'readytoship', 'preparing']
      options['page'] = 1
      options['itemsPerPage'] = 100
      const { data, success } = await getAllOrders(options)
      if (success) {
        this.newOrders = data.orders
        this.loading = false
      }
      return {
        success,
        data
      }
    },

    async getTrashOrders(options: OrderQueryParameters | any) {
      this.loading = true
      options.status = ['trash']
      const { data, success } = await getAllOrders(options)
      if (success) {
        this.trashOrders = data.orders
        this.trashTotalItems = data.total_items
        this.loading = false
      }
      return {
        success
      }
    },

    async deleteOrders(payload: any) {
      const { success } = await updateOrders(payload)
      if (success) {
        return {
          success: true
        }
      } else {
        return {
          success: false
        }
      }
    },

    async deleteTrashOrders(payload: any) {
      const { success } = await updateOrders(payload)
      if (success) {
        await this.getTrashOrders({ page: this.page, itemsPerPage: this.itemsPerPage })
      }
      return {
        success
      }
    },

    async restoreTrashOrders(payload: any) {
      const { success } = await updateOrders(payload)
      if (success) {
        await this.getTrashOrders({ page: this.page, itemsPerPage: this.itemsPerPage })
      }
      return {
        success
      }
    },

    async editOrder(id: any, payload: any) {
      const { success, data, error } = await updateOrder(id, payload)
      if (success) {
        return {
          success: true,
          data
        }
      } else {
        return { sucess: false, error: error }
      }
    },

    async deleteOrder(id: number) {
      const { success } = await deleteOrder(id)
      if (success) {
        this.orders = this.orders.filter((order: Order) => order.id !== id)
      }
    }
  }
})
