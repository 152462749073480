import api from '@/core/api'
import { toast } from 'vue3-toastify'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import { handleError } from '@/helpers/handleError'
import { toastDone } from '../util/toastHelper'

export async function deleteAttribute(id: number): Promise<ApiResponse> {
  try {
    const toastId = toast.loading('جاري حذف الوحدة')
    await api.delete(`/wp-json/wc/v3/products/attributes/${id}?force=true`)
    toastDone(toastId, 'تم حذف الوحدة بنجاح')
    return {
      success: true
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
