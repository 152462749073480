import api from '@/core/api'
import { toast } from 'vue3-toastify'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import { handleError } from '@/helpers/handleError'
import { toastDone } from '../util/toastHelper'

export async function createAttribute(createData: {
  name: string
  type: string
  translation_strings?: { [key: string]: string }
  slug: string
}): Promise<ApiResponse> {
  try {
    const toastId = toast.loading('جاري انشاء الوحدة')
    const requestBody = {
      name: createData.name.trim(),
      type: createData.type.trim(),
      translation_strings: createData.translation_strings,
      slug: createData.slug
    }
    const { data } = await api.post(`/wp-json/wc/v3/products/attributes`, requestBody)
    toastDone(toastId, 'تم انشاء الوحدة بنجاح')
    return {
      success: true,
      data
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
