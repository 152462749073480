import { createApp } from 'vue'
import './assets/main.css'
import './styles/style.scss'
import './styles/settinges.scss'

import App from './App.vue'
import { registerPlugins } from './plugins'
import { loadFonts } from './plugins/webfontLoader'
import CKEditor from '@ckeditor/ckeditor5-vue'
loadFonts()

const app = createApp(App)
app.use(CKEditor)
registerPlugins(app)

app.mount('#app')
